<template>
  <div class="row items-center justify-center full-width q-pa-sm border-bottom">
    <q-avatar
      color="grey-4"
      text-color="dark"
      class="q-mr-sm"
      size="2.4rem"
      :icon="icon"
    >
      <q-badge
        v-if="selected"
        color="positive"
        floating
        rounded
      >
        <q-icon name="done" />
      </q-badge>
    </q-avatar>

    <div class="text-weight-bold text-subtitle1 text-capitalize">
      {{ (data.placeAlias || '') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlaceSection',
  props: {
    selected: {
      type: Boolean,
      default () {
        return false
      }
    },
    data: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      icons: {
        default: 'place',
        employee: 'shopping_basket'
      },
      labels: {
        employee: 'basket'
      }
    }
  },
  computed: {
    icon () {
      return this.icons[this.data.type] || this.icons.default
    }
  }
}
</script>
